.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  justify-content: center;
}

.cls-1 {
  fill: #000;
}

.cls-2 {
  fill: #436386;
}

.cls-3 {
  fill: #fff;
}

.cls-4 {
  fill: #436386;
}
