.customTopButton {
.fc-toolbar-title {
  // color:#436386;
}
  .fc-button {
    background-color: #436386;
    border-radius: 6px;
    border-color: #436386;
    
  }

  // .fc-button-active {
    // background-color: white !important;
    // color: #436386 !important;
    // border-color: #436386 !important;
  // }



}
