.externalEvent {
  #external-events {
    position: absolute;
    z-index: 2;

    padding: 0 10px;
    border: 0.5px solid #d9d9d9;
    overflow-y: scroll;
    color: white;
  }

  .fc-event {
    cursor: move;
    margin: 3px 0;
    background-color: #436386;
    border-radius: 2px;
    border-color: #436386;
  }

  #calendar-container {
    position: relative;
    z-index: 1;
    margin-left: 200px;
  }

  #calendar {
    max-width: 1100px;
    margin: 20px auto;
  }

  .fc-event-1 {
    cursor: move;
    margin: 3px 0;
    background-color: #9e6e58;
    border-radius: 2px;
    height: 20px;
    border-color: #9e6e58;
  }

  .fc-event-15 {
    cursor: move;
    margin: 3px 0;
    background-color:#B3ABD6;
    border-radius: 2px;
    height: 30px;
    border-color: #B3ABD6;
  }

  .fc-event-2 {
    cursor: move;
    margin: 3px 0;
    background-color: #436386;
    border-color:#436386;
    border-radius: 2px;
    height: 40px;
  }

  .fc-event-3 {
    cursor: move;
    margin: 3px 0;
    background-color: #cbcdb5;
    border-color: #cbcdb5;
    border-radius: 2px;
    height: 62px;
  }

  .fc-event-4 {
    cursor: move;
    margin: 3px 0;
    background-color: #bd9e9c;
    border-color: #bd9e9c;
    border-radius: 2px;
    height: 85px;
  }

  .fc-event-5 {
    cursor: move;
    margin: 3px 0;
    background-color: #b7695d;
    border-color:#b7695d;
    border-radius: 2px;
    height: 108px;
  }

  .fc-event-6 {
    cursor: move;
    margin: 3px 0;
    background-color: #935854;
    border-color: #935854;
    border-radius: 2px;
    height: 130px;
  }

  .fc-event-7 {
    cursor: move;
    margin: 3px 0;
    background-color: #bd4361;
    border-color:#bd4361;
    border-radius: 2px;
    height: 152px;
  }

  .fc-event-8 {
    cursor: move;
    margin: 3px 0;
    background-color: #89dbd4;
    border-color:#89dbd4;
    border-radius: 2px;
    height: 174px;
  }
}
