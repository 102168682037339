.box-layout {
  align-items: center;
  /* background: url("/images/bg.jpg"); */
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.box-layout__register {
  align-items: center;
  /* background: url("/images/bg.jpg"); */
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  min-width: 600px;
}
