/* @import "~antd/dist/antd.css"; */

.site-layout .site-layout-background {
  /* background: red; */
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

body {
  margin: 0;
  padding: 0;
}
