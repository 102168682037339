.landing-layout {
  align-items: center;
  background: url("./../images/6.jpg");
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.landing-layout__box {
  background: fade-out(white, 0.35);
  border-radius: 3px;
  padding: $m-size $s-size;
  text-align: center;
  width: 20rem;
}

.landing-layout__title {
  margin: 0 0 $xs-size 0;
  line-height: 1;
  font-weight: 800;
}
