.stepper-container {
}

.stepper-header {
}

.stepper-body {
  display: flex;
  justify-content: space-between;
  align-items: top;
  // position: relative;
  padding: 24px 0 0 0;
  min-height: 380px;
}

.stepper-footer {
  display: flex;
  justify-content: space-between;
  // border-style: dotted;
  align-items: center;
}
