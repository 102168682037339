.matterport-showcase iframe {
  min-height: 240px;
  margin: 16px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .matterport-showcase iframe {
    min-height: 360px;
  }
}

@media screen and (min-width: 992px) {
  .matterport-showcase iframe {
    height: 600px;
    max-width: 1068px;
    margin: 0 auto 15px;
  }
}

.matterport-showcase {
  text-align: center;
}
