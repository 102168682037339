.customCalendarEvent {

// .fc-timegrid-col.fc-day-today{
//   background-color: #436386;
//   opacity: 5%;
// }

.fc-timegrid-col.fc-day-today {
  background-color: rgba(67, 99, 134, 0.05) !important;
}
.fc-day-today {
  background-color: rgba(67, 99, 134, 0.05) !important;
}

// .fc-day-today {
//   background-color: #436386;
//   opacity: 5%;
// }

// .fc-day-today {
//   background-color: #436386;
//   opacity: 5%;
// }

// div:not(.fc-event-main) .fc-timegrid-col.fc-day-today {
//   background-color: #436386;
//   opacity: 5%;
// }

.fc-event-main {
  white-space: pre-wrap !important;
  // background-color: red;
  // color: "black";
  
}

}