.loader-spin {
  display: flex;
  justify-content: center;
  vertical-align: center;
}

.loader-spin-empty-screen {
  align-items: center;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
