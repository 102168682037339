.backtopDivWrapper {
  .ant-btn {
    border-radius: 4;
    background: #436386;
    opacity: 0.33;
    color: white;
    text-align: "center";
    font-size: 18;
    
  }

  .ant-btn:hover {
    opacity: 1;
  }
  
}