.bookButtonWrapper {
  .ant-btn {
    width: 100%;
    background-color: #ccecfa;
    color: #435f71;
    border: transparent;
    // border: 2px solid transparent;
  }
  .ant-btn:active {
    background-color: red;
  }

  .ant-btn:hover {
    opacity: 1;
    background: #ffe500;
    // border: 2px solid #435f71;
  }
  .ant-btn:focus {
    color: white;
    background-color: #428bca;
  }

  .ant-btn:focus .ant-btn:active .anticon {
    font-size: 16px;
    margin-right: 10px;
  }
}

.bookButtonWrapperActive {
  .ant-btn {
    width: 100%;
    color: white;
    background-color: #428bca;
    border: transparent;
    // border: 2px solid transparent;
  }
  .ant-btn:active {
    color: white;
    background-color: #428bca;
  }

  .ant-btn:hover {
    opacity: 1;
    background: #ffe500;
    // border: 2px solid #435f71;
  }
  .ant-btn:focus {
    background-color: red;
  }

  .ant-btn:focus .ant-btn:active .anticon {
    font-size: 16px;
    margin-right: 10px;
  }
}
